body {
    &.party-mode {
        background-color: #ff69b4;
        transition: background-color 0.5s ease-in-out;
        animation: disco 1s infinite alternate;
    }
}

@keyframes disco {
    0% { background-color: #ff69b4; }
    25% { background-color: #ff4500; }
    50% { background-color: #ffd700; }
    75% { background-color: #32cd32; }
    100% { background-color: #1e90ff; }
}
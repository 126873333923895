@import './../variables';

.mention {
    position: relative;
    display: inline-block;
    color: $primaryColor;
    cursor: pointer;
    
    &:hover, &.hover {
        text-decoration: underline;
        
        .user-card {
            display: flex;
        }
    }
    
    .user-card {
        display: none;
        position: fixed;
        z-index: 100;
        text-decoration: none;
        color: #000;
        columns: inherit;
        padding: 10px;
        justify-content: center;
        align-items: center;
        background: #fff;
        box-shadow: 0 4px 4px rgba(0,0,0,0.4);
        border-radius: $standardBorderRadius;
        
        .img-container {
            display: block;
            width: 50px;
            height: 50px;
            overflow: hidden;
            border-radius: 50%;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        .user-name {
            display: block;
            white-space: nowrap;
            margin-left: 10px;
        }
    }
}

.discussion-text {
    color: #22445F;
    word-wrap: break-word;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    &.truncate-multi-line {
        line-clamp: 3;
        -webkit-line-clamp: 3;
    }
    
    a {
        color: $primaryColor;
    }
}

.editor-placeholder::before {
    display: block;
    content: attr(data-placeholder);
    color: #878787;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
}
$topSpacing: 102.5px;
.hub-container {
    color: #fff;
    margin-top: -$topSpacing;
    padding: $topSpacing 10px 20px;
    background: linear-gradient(to right, #2D71F4, #FA02FF);
    height: 140px;
    position: sticky;
    top: 0;
    z-index: 11;
    
    .title {
        margin-bottom: 14px;
        font-size: .75em;
    }
    .plus-icon {
        margin: 0px 6px 0px -1px;
        width: 22px;
    }
}
.head-container {
    max-width: 610px;
    margin: 0px auto;
    margin-bottom: 0px;
}
.entity-add-controls {
    display: flex;
    padding: 13px 20px;
    margin: 0 -10px;
    
    .control {
        color: #000;
        cursor: pointer;
    }

    &.no-filter {
        padding: 30px 20px 20px;
        margin: 0;
    }
}
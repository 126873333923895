// .tabs {
//     display: flex;
//     list-style-type: none;
//     padding: 10px;
//     background: $tabBackgroundColor;
//     overflow: auto;
//     border-bottom: 1px solid #FFB5B5;
    
    
//     li {
//         flex-shrink: 0;
        
//         + li {
//             margin-left: 10px;
//         }  
//     } 
    
//     a {
//         color: #000;
//         text-decoration: none;
        
//         &.selected {
//             background: $tabBackgroundColor;
//             color: #fff;
//         }
//     }
// }


.tabs {
    display: flex;
    list-style-type: none;
    width: 100%;
    padding: 10px;
    overflow: auto;
    overflow-y: hidden;
    background: $tabBackgroundColor;
    
    &.loaded {
        background: $tabBackgroundColor;
    }
    
    
    a {
        position: relative;
        z-index: 2;
        color: #000;
        text-decoration: none;
        flex-shrink: 0;
        // background: #fff;
        
        + a {
            margin-left: 10px;
        }
        
        &.selected {
            color: #fff;
            box-shadow: 0px 0 100px 100px white, 0px 0 200px 200px white, 0px 0 400px 400px white, 0px 0 800px 800px white, 0px 0 1600px 1600px white;
            z-index: 1;
        }
    } 
    
    &.filter-tabs {
        background: transparent;
        padding: 10px $containerMobilePadding;
        
        li {
            flex-shrink: 0;
            color: #000;
            
            
        }
    }
}

@import './../variables';

button {
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    border: none;
}

.btn {
    display: inline-block;
    position: relative;
    line-height: 1.5rem;
    padding: 5px 15px;
    text-decoration: none;
    text-align: center;
    border-radius: $standardBorderRadius;
    font-size: 1rem;
    background: none;
    cursor: pointer;
    transition: padding 0.3s ease;
    
    &.btn-primary {
        color: #fff;
        background-color: $primaryColor;
    }
    
    &.btn-secondary {
        color: $primaryColor;
        background: #fff;
        border: 1px solid $primaryColor;
    }

    &.btn-alternative {
        color: #000000;
        background-color: #E3E4EB;
    }
    
    &.btn-rounded {
        padding: 8px 18px;
        border-radius: 50px;
    }
    
    &.btn-skinny-rounded {
        padding: 3px 13px;
        border-radius: 12px;
    }

    &.btn-hub {
        border: 1px solid #fff;
    }

    &.btn-caution {
      color: red;
      border: 1px solid #ff8800;
  }
    
    &.btn-fabulous {
        border: 1px solid blue;
        color: blue;
        background: #fff;
    }
    
    &.btn-submit {
        display: flex;
        justify-content: center;
    }
    
    &.btn-large {
        padding: 15px;
        border-radius: 50px;
    }
    
    &:after {
        position: absolute;
        top: 5px;
        left: 13px;
        content: "\2713";
        color: #fff; /* Set the color of the checkmark */
        padding-left: 5px;
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    
    &.added{
        color: #fff;
        padding-left: 40px;
        background: $primaryColor;
    
        &:after {
            opacity: 1;
        }
    }
}

.btn-filter {

    text-align: center;
    display: block;
    width: fit-content;
    line-height: 1.5rem;
    text-decoration: none;
    cursor: pointer;

    border: 1px solid #ccc;
    font-size: .8125em;
    margin: 5px;
    padding: 3px 12px;
    border-radius: 24px;
    background-color: transparent; 
    
}

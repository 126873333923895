.node-card {
    display: block;
    padding: 10px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    text-decoration: none;
    color: inherit;
    flex-grow: 1;
    
    &.no-boundary {
        border: none;
    }
    
    .text-muted {
        font-weight: lighter;
        color: #22445F;
    }
}
.node-interaction {
    display: flex;
    justify-content: space-between;
    align-items: center;    
}

.node-description {
    margin-top: 8px;
    max-height: 3em;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}


.node-tile {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    margin-right: 10px;
    
    .node-tile-image {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        flex-shrink: 0;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
        
        &.square {
            img {
                border-radius: 4px;
            }
        }
    }
    
    .node-tile-info {
        display: flex;
        flex-direction: column;
        min-width: 0; 
        
        .info-title {
            color: #000;
            font-size: 1rem;
        }
    }
}
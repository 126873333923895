@import '../variables';

form {
    .form-title {
        margin-bottom: 30px;

    }
    
    .field-group {
        position: relative;
        margin-bottom: 16px;
        width: 100%;
    }
    
    .form-btn {
        width: 100%;
        border-radius: 25px;
    }
    
    label {
        display: block;
    }
    
    .fancy-shmancy {
        input, textarea {
            width: 100%;
            padding: 25px 15px 8px;
            background: #EFEFEF;
            border: 0;
            border-radius: 15px;
            font-family: CircularStd, Arial;
            font-size: 1em;
            
            + label, + * + label {
                position: absolute;
                top: 15px;
                left: 15px;
                color: #878787;
                transition: all 0.26s ease-in-out;
                white-space: nowrap;
                overflow: hidden;
                pointer-events: none;
            }
            
            
            
            &:focus, &.has-content, &.always-has-content {
                + label, + * + label {
                    top: 8px;
                    font-size: 0.75rem;
                    font-weight: 300;
                    max-width: 100%;
                }
            }    
        }
        select, .select {
            width: 100%;
            padding: 25px 15px 8px;
            background-color: #EFEFEF;
            border: 0;
            border-radius: 15px;
            background-size: 16px 16px; 
            padding-right: 40px; 
            
            &::-ms-expand {
                display: none; 
            }

            + label, + * + label {
                position: absolute;
                top: 15px;
                left: 15px;
                color: #878787;
                transition: all 0.26s ease-in-out;
                white-space: nowrap;
                overflow: hidden;
                pointer-events: none;
            }

            &:focus, &.has-content, &.always-has-content {
                + label, + * + label {
                    top: 8px;
                    font-size: 0.75rem;
                    font-weight: 300;
                    max-width: 100%;
                }
            } 
        }
    }
    
    .hh-checkbox {
        display: flex;
        align-items: center;
        
        label {
            display: inline-block;
            position: relative;
            cursor: pointer;
            font-size: 22px;
            user-select: none;
            
            input {
                left: 0;
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
                
                &:checked ~ .checkmark {
                    background-color: $primaryColor;
                    
                    &:after {
                        display: block;
                    }
                }
            }
            
            .checkmark {
                display: inline-block;
                margin-right: 8px;
                position: relative;
                height: 15px;
                width: 15px;
                background-color: #eee;
                border-radius: 4px;
                
                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    
                    left: 5px;
                    top: 2px;
                    width: 3px;
                    height: 8px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }
            
            .label {
                font-size: 1rem;
            }
        }
    }
}

select, .select {
    position: relative;
    background: url('../assets/icon-down-arrow.svg') no-repeat right 10px center;
    background-size: 16px 16px; 
    padding-right: 40px; 
    
    &::-ms-expand {
        display: none; 
    }
}


input, select, .select {
    display: block;
    height: 49px;
    width: 100%;
    padding: 10px;
    border-radius: 15px;
    border: 0px solid #adadad;
    background-color: #EFEFEF;
    -webkit-appearance: none;
    appearance: none;
    font-size: 1em;
    outline: none;
}


::-webkit-file-upload-button, ::file-selector-button {
    display: none;
}

.modal-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
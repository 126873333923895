@import '../variables';

@font-face {
    font-family: CircularStd;
    font-weight: 400;
    src: url("/assets/fonts/CircularStd-Book.otf") format("opentype");
}

@font-face {
    font-family: CircularStd;
    font-weight: 700;
    src: url("/assets/fonts/CircularStd-Bold.otf") format("opentype");
}

@font-face {
    font-family: CircularStd;
    font-weight: 300;
    src: url("/assets/fonts/CircularStd-Light.otf") format("opentype");
}

@font-face {
    font-family: 'Albert Sans';
    src: url('/assets/fonts/AlbertSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('/assets/fonts/AlbertSans-Normal.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Albert Sans';
    src: url('/assets/fonts/AlbertSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

:root {
    touch-action: pan-x pan-y;
    height: 100% 
}


* {
    box-sizing: border-box;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1.25em;
}

h4 {
    font-size: 1em;
}

h5 {
    font-size: 0.83em;
}

h6 {
    font-size: 0.673m;
}

small {
    font-size: 0.673em;
}

html {
    height: initial;
}

html, body {
    font-family: 'CircularStd', 'Arial';
    font-weight: normal;
    font-size: 16px;
    color: $primaryTextColor;
}

body {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    margin: 0;
    
    &.no-scroll {
        overflow: hidden;
    }
}

p {
    line-height: 1.5em;
}

a {
    color: inherit;
    
    &:active {
        color: inherit;
    }
}

*:not(html):not(body)::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
}
$primaryFadedBackground: #FAF7FF;
$primaryColor: #7B1ED7;
$secondaryColor: #8879A1;
$tabBackgroundColor: linear-gradient(to right, #E0327E 0%, #FF5D02 100%);
$standardBorderRadius: 4px;
$errorColor: #8f1515;
$successColor: #007a04;
$primaryTextColor: #000000;
$greyedOutTextcolor: #092C47;

$containerPadding: 20px;
$containerMobilePadding: 10px;

$containerWidth: 640px;

@import '_variables';
@import 'scss/reset.scss';
@import 'scss/forms.scss';
@import 'scss/tabs.scss';
@import 'scss/node.scss';
@import 'scss/hub.scss';
@import 'scss/price.scss';
@import 'scss/buttons.scss';
@import 'scss/products.scss';
@import 'scss/entity-add-controls.scss';
@import 'scss/post.scss';
@import 'scss/party.scss';

footer {
    margin-top: auto;
}


@media screen and (min-width: $containerWidth) {
    .page-content {
        margin: 42px auto;
        border-radius: 8px;
        background-color: #FFF !important;
        border: 1.1px, solid, #D4DDE4 !important;
    }

    .container {
        padding: 0 $containerPadding
    }
}

.page-content {
    padding: 15px 10px;
    max-width: $containerWidth;    
    background-color: transparent;
    border-color: transparent;
}

.container {
    max-width: $containerWidth;
    margin: 0 auto;
    padding: 0 $containerMobilePadding;
}

.entity-tab-container {
    max-width: $containerWidth;
    margin: 0 auto;
    padding: 0 10px;
}

.content-container {
    margin: 0 auto;
    max-width: $containerWidth;
}

.text-center {
    text-align: center;
}

.flex-column {
    flex-direction: column;
}

.flex {
    display: flex;
    
    &.flex-center {
        align-items: center;
        justify-content: center;
    }
    
    &.space-between {
        justify-content: space-between;
    }
    
    &.align-center {
        align-items: center;
    }
    
    &.align-start {
        align-items: flex-start;
    }
}

.flex-grow {
    flex-grow: 1;
}

.align-center {
    align-self: center;
}

.grid {
    display: grid;
}

.grid-columns-5 {
    grid-template-columns: repeat(5, calc(20% - 8px));
    gap: 10px;
}

.grid-columns-3 {
    grid-template-columns: repeat(3, calc(33.33% - 6.666666667px));
    gap: 10px;
}

.grid-columns-2 {
    grid-template-columns: repeat(2, calc(50% - 5px));
    gap: 10px;
}

.grid-columns-1 {
    grid-template-columns: 100%;
    gap: 40px;
}

.info-block-container {
    display: flex;
}

.info-block {
    display: flex;
    flex-direction: column;
    
    .info-block-title {
        font-weight: bold;
    }
}

.error {
    color: $errorColor;
    font-size: 0.8em;
}

.success {
    color: $successColor;
    font-size: 1em;
}

.text-muted {
    color: #848484;
}

.small {
    font-size: 0.8125rem;
}
body {
    background-color: #f7f7f7;
}

.message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(51, 51, 51, 0.2);
    z-index: 1000;
}

#sticky-stuff {
    position: sticky;
    transition: top 0.3s;
    background-color: white;
    z-index: 10;
}

/* post image carousel button */
.slick-next {
    display: none !important;
}